import React from "react";

var currentTime = new Date();
var currentYear = currentTime.getFullYear();


function Footer() {
    return <div>
    <footer>
        <p>Copyright ⓒ {currentYear}</p>
    </footer>
    </div>
}

export default Footer;