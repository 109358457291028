import React from "react";


function Header() {
    return <div>
    <header>
        <h1>Niagara Produce</h1>
    </header>
    </div>
}


export default Header;