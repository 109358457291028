import React from "react";



function Note() {
    return <div class="note">
        <h1>Title Will Go Here</h1>
        <p>Content will go here</p>
    </div>
}

export default Note;